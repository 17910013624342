.view-video-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .top-nav{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        .back-button{
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;

            span{
                margin-left:05px;
            }
        }
    }

    .video-title{
        align-items: flex-start;
        justify-content: flex-start;
        color: white;
        width: 98%;
        margin-top: 10px;
        // border: 1px solid white;

        h2{
            margin: 0;
            padding: 0;
            color: white;
        }
    }

    .video-description{
        p{
            color: rgb(237, 237, 237);
            width: 98%;
            text-align: justify;
        }
    }
}