.question {
    position: relative; /* Position relative for absolute positioning of buttons */
    margin-bottom: 20px;
    padding: 20px;
    
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #fff;
    }
  
    .question-image {
      max-width: 100%;
      margin-bottom: 10px;
    }
  
    .options {
      .option {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        margin-bottom: 10px;
  
        input[type="radio"] {
          margin-right: 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #ccc;
          border-radius: 50%;
          outline: none;
          cursor: pointer;
          transition: background-color 0.3s;
        }
  
        input[type="radio"]:checked {
          background-color: #ffa500;
          border-color: #ffa500;
        }
  
        label {
          font-size: 16px;
          color: #fff;
        }
  
        .option-image {
          max-width: 100%;
          margin-top: 5px;
        }
      }
    }
  
    .navigation-buttons {
      position: absolute; /* Position absolute for top right corner */
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 10px;
      margin-right: 10px;
  
      button {
        margin-bottom: 10px;
      }
    }
  }
  