@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    background-color: #3b567a;
    font-family: 'Montserrat', sans-serif;
}

/* Hide the carousel on large screens */


  
.root-component {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // min-height: 100vh; /* Full viewport height */
    position: relative; /* Ensure footer stays at the bottom */
}

.root-component-login {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100%;
    // min-height: 100vh; /* Full viewport height */
    position: relative; /* Ensure footer stays at the bottom */
}

.w100{
    width: 100%;
}

.h100{
    height: 100%;
}

.image.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1; /* Takes available space */
    width: 100%; /* Ensure it takes the full width available */
}

.image.center img {
    // min-width: 20%; /* Makes sure the image scales down on smaller screens */
    // height: auto; /* Maintains the aspect ratio */
    max-width: 30%; /* Ensures it doesn't overflow the viewport */
}

.footer-img {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    // width: 100px; /* Set a default width */
    max-width: 100%; /* Ensures it doesn't overflow the viewport */
}


.loginscreen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
}

.custom-form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.custom-form-group input {
    width: 80%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #697884;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);



}

.custom-form-group button {
    width: 30%;
    margin: 10px 0;
    padding: 10px;
    // border: 1px solid #000;
    border: none;
    border-radius: 5px;
    background-color: #3b567a;
    color: #fff;
    cursor: pointer;
    // shadow
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
}

.carousel-banners {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  
    overflow: hidden;
}

.dashboard-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
}

.hi-name {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
    margin: 10px;
    font-family: 'Montserrat', sans-serif;
}

// .hi-name span {
//     margin: 0px;
//     font-size: rem;
//     color: orange;
// }

.hi-name p {
    margin: 0px;
    color: #fff;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.enrolled-course {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  
    overflow: hidden;
    background-color: #3b567a;
    margin-bottom: 10px;
}

.course-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: 0 10px;
    text-decoration: none;

    // margin: auto;
    // border-radius: 10px;
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  
    // overflow: hidden;
    // background-color: #3b567a;
}

.course-thumbnail {
    width: 100px;
    height: 100px;
    margin: 10px 0;
    border-radius: 10px;
    
    align-items: flex-start;
}


.course-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: left;
    width: 100%;
    margin: 10px;
}
.course-description{
    margin-bottom: 20px;
    text-align: left;
    padding: 0;
    margin: 10px;
    color: #cfcfcf;

}

.course-title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
    padding: 0;
    margin: 10px;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
}


.subject-list {
    display: flex;
    justify-content: space-evenly;
    align-items: left;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    border-radius: 10px;
}

.subject-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:180px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  
    overflow: hidden;
    background-color: #3b567a;
    border-radius: 10px;
}

.subject-image {
    width: 150px;
    height: 150px;
    margin: 10px;
    align-items: flex-start;
    border-radius: 05px;
    overflow: hidden;
    background: #838383;
}

.subject-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subject-title{
    color: #fff;
    font-size: 14px;
    width: 170px;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
    text-wrap: wrap;
    padding: 0;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


/* NotFound.css */

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.not-found-image {
    max-width: 300px; /* Adjust the width as needed */
    width: 100%;
    height: auto;
    filter: invert(1);
    margin-bottom: 20px;
}

.not-found-title {
    font-size: 3rem;
    color: #ffffff;
    margin-bottom: 10px;
}

.not-found-text {
    font-size: 1.5rem;
    color: #e6e6e6;
}

.top-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.top-section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 10px;

}
.top-section-header h1{
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    margin-bottom: 10px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
.top-section-content {
    width: 100%;
    margin: auto;

  }

.top-section-content ul{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
}

.top-section-content li {
    list-style-type: none;
    display: inline-block; /* Optional: to keep li elements inline */
    position: relative; /* Needed for positioning the ::after pseudo-element */
    color: #e1e1e1;
    
    margin: 0;
    font-size: 14px;
}

.top-section-content li:not(:last-child)::after {
    content: "|"; /* Add the character " |" */
    padding-left: 10px; /* Adjust the padding as needed */
    padding-right: 10px; /* Adjust the padding as needed */
}


.root-content{
    text-align: center;
}


.tab-navigation{
    margin-top: 30px;
    margin-bottom: 20px;
}

.tab-navigation button {
    width: 22%;
    margin: auto;
    background-color: transparent;
    border: none;
    color: #fff;
    margin: 1px;
    padding: 10px;

}

.activetab {
    border-bottom: 1px solid #fff !important;
}

.item-list{
    // margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.item-list ol {
    width: 95%;
    padding: 0;
}

.item-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding:20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  
    overflow: hidden;
    background-color: #5777a1;
    margin-bottom: 10px;
}

// .item-list li {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 95%;
//     padding:20px;
//     margin-bottom: 10px;
//     border-radius: 10px;
//     box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  
//     overflow: hidden;
//     background-color: #5777a1;
//     margin-bottom: 10px;
// }

// .item-list ol {
//     margin: 0;
//     padding: 0;
//     list-style: none;
// }

// .item-list li {
//     max-width: 100%;
//     margin: auto;
//     // padding: 20px;
//     // margin-bottom: 10px;
//     // border-radius: 10px;
//     // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
//    border: 1px solid black;

// }

// .item-list li a {
//     border: 1px solid #5777a1;
//     width: 90%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 20px;
//     text-decoration: none;
//     color: #fff;
// }

.tab-contents h2 {
    margin: 0;
    padding: 0;
}
.tab-contents p {
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    text-decoration: none;
}

.icon {
   color: #fff;
}


.content-title{
    color: #fff;
    font-size: 14px;
    text-align: left;
    padding: 0;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.no-internet-image{
    width:100px;
}

.nointernet-title{
    color: red;
    font-size: 20px;
    font-style: bold;
}

.nointernet-description{
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
}




.no-internet {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #e1e1e1;
    color: #171717;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nointernet-button{
    background-color: #2B411C;
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
  

    .footer .text-center{
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 10px;

    }

    .home-buttons{
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .home-buttons button{
        margin-left: 10px;
        width: 30%;
    }

    .home-buttons .signup {
        margin: 0;
        background-color: #738db0;
        width: 120px;
    }

    .noaccount{
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 30px;
        color: white;
        font-weight: 100;
    }

    .bm-item-list{
        background-color: #2c411b;
        height: 100%;
    }

   .menu-item{
        color: white;
        padding: 10px;
        border-bottom: 1px solid #5d5d5c;
        margin: 0 10px;
        width: 50%;
    }

    .top-nav{
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        .back-button{
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;

            span{
                margin-left:05px;
            }
        }
    }
//for mobile and tablet
@media (max-width: 768px) {
    .image.center img {
        // min-width: 20%; /* Makes sure the image scales down on smaller screens */
        // height: auto; /* Maintains the aspect ratio */
        max-width: 70%; /* Ensures it doesn't overflow the viewport */
    }
}


.text-center{
    text-align: center;
}

.text-white{
    color: #fff;
}


.text-customed-as-logo{
    font-family: 'Montserrat', sans-serif;
    font: 1em sans-serif;
    font-weight: 500;
    font-size: 32px;
}

.no-data{
    color: #FFBC09;
}