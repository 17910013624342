@media (min-width: 724px) { /* Adjust the breakpoint as needed */
    .hide-on-large-screen {
      display: none;
    }
  }


  .hi-name{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    text-align: left;
    padding: 0;
    margin: 0;
    flex-direction: column;

    .hi-text{
      margin: 0 10px;
    }
    span{
      margin-left: 10px;
      color: #FFBC09;
    }
    p{
      display: block;
      margin: 5px 10px 20px 10px;
    }
  
  }


  .enrolled-courses{
    margin-top: 15px;
    .title{
      color: white;
      margin: 0 08px 5px;
      font-size: 18px;
      padding:0 5px;
      justify-content: flex-start;
    }
  }

  .other-courses{
    .title{
      color: white;
      margin: 0 08px 5px;
      font-size: 18px;
      padding:0 5px;
      justify-content: flex-start;
    }
  }



  .enrolled-courses-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  /* dashboard.scss */
  .enrolled-courses-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding-bottom: 10px; /* Add some padding at the bottom for better visibility */
    /* Hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    padding-left: 10px;


    .enrolled-course {
      flex: 0 0 auto; /* Prevent flex items from shrinking */
      width: 350px; /* Adjust the width as needed */
      margin: 0;
      margin-right: 20px; /* Space between courses */
      border: 1px solid #5777a1;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      background: #dfdfdf07; /* Add background color for better visibility */
    }
  
    .course-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      padding: 10px;
      
  
      .course-thumbnail {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
        margin-right: 15px;
      }
  
      .course-details {
        flex: 1;
  
        .course-title {
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }
      }
  
      .icon {
        font-size: 20px;
      }
    }
  }

  .other-courses-list{
    display: flex;
    flex-direction: column;

    .other-course{
      // border: 1px solid rgb(205, 205, 205);
      padding: 0 10px;
      border-radius: 15px;
      box-shadow: 0 2px 5px rgba(222, 222, 222, 0.1);
      width: 96%;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: #5776a0;

      .course-link {
        width: 98%;
        margin-left: 0;

      .course-details{
        .course-title{
          font-size: 20px;
        }

      }
    }

    }


  }

  .floating-button {
    position: fixed;
    width: 40px;
    height: 40px;

    bottom: 20px;
    right: 20px;
    background-color: #FFBC09;
    color: white;
    border: none;
    // padding: 10px 20px;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 10px;
  }
  
  .floating-button:hover {
    background-color: #f9c63c;
  }

  .home-btn{
    font-size: 20px;
  }
  