.normal-div{
    color: white;

    .top-section{
        color: white;

        .top-section-header{
            color: white;

        }
 
    }


}

.floating-button-top {
    position: fixed;
    width: 40px;
    height: 40px;

    top: 20px;
    left: 20px;
    background-color: #ff6a00;
    color: white;
    border: none;
    // padding: 10px 20px;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 10px;
  }
  
  .floating-button-top:hover {
    background-color: #ff6a00;
  }

  .home-btn-top{
    font-size: 20px;
  }


/* Hide the element by aria-describedby attribute */
[aria-describedby="rpv-core__tooltip-body-toolbar-more-actions"] {
  display: none !important;
}

/* Hide the element by aria-describedby attribute */
[aria-describedby="rpv-core__tooltip-body-get-file"] {
  display: none !important;
}

/* Hide the element by aria-describedby attribute */
[aria-describedby="rpv-core__tooltip-body-print"] {
  display: none !important;
}

/* Hide the element by aria-describedby attribute */
[aria-describedby="rpv-core__tooltip-body-open"] {
  display: none !important;
}


