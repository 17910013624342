/* Add this to your CSS file */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  .question {
    margin: 20px 0;
  }
  
  .options {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    margin: 5px 0;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .result {
    margin: 20px;
  }
  
  .result-item {
    margin: 10px 0;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: red;
  }

  .practice-mcq {
    position: relative;
  }
  
  .navigation-buttons {
    // position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .next-previous-buttons {
    margin-bottom: 10px; /* Add margin between Next and Previous buttons */
  }
  
  .submit-button {
    position: fixed;
    top: 10px;
    right: 10px;
  }

  .submit-button {
    button {
      /* Define the style for the button */
      background-color: #fc7a5c; /* Green */
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 8px;
    }
  }


  .navigation-buttons {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom of the page */
    right: 20px; /* Adjust the distance from the right side of the page */
    display: flex;
    flex-direction: column-reverse; /* Align the containers in a column-reverse direction */
  }
  
  .submit-button {
    margin-bottom: 10px; /* Adjust the margin between the "Submit" button and the "Next" and "Previous" buttons */
    
    button {
      background-color: #4CAF50; /* Green */
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      border-radius: 8px;
      width: 100%; /* Make the button fill the width of its container */
    }
  }
  
  .next-previous-buttons {
    button {
      background-color: #008CBA; /* Blue */
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin-bottom: 10px; /* Adjust the margin between the "Next" and "Previous" buttons */
      cursor: pointer;
      border-radius: 8px;
      width: 100%; /* Make the button fill the width of its container */
    }
  
    button:first-child {
      background-color: #f44336; /* Red */
    }
  }

 
  
  


  
  