.menu {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000000;
    background-color: #3b567a;
    padding: 20px;

    .close-btn{
        // position: relative;
        float: right;
        margin-right: 20px;
        top: 10px;
        right: 10px;
        padding: 20px;
        color: white;
    }

    .menu-user-info{
        margin-top: 10%;
    }
    
    .image-avatar{
        width: 60px;
        height: 60px;
    }

    .menu-item{
        width: 80%;
        text-decoration: none;

        .menu-text{
            margin-left: 10px;
            font-size: 16px;
        }
    }

}