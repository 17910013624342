
.other-courses{
    .previous-details{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 20px;

        .preimage{
            img{
                width:100px;
                height: 100px;
                border-radius: 15px;

            }
        }

        .other-course-details{
            margin: 0 15px;
            padding: 0;

            .package-name{
                color: rgb(208, 208, 208) !important;
                font-size: 15px;
            }

            h2{
                margin: 0;
                padding: 0;
                font-size: 18px;
                margin-top: 05px;
            }
            p{
                margin: 0;
                padding: 0;
            }

            .sticker{
                background-color: #5776a0;
                padding: 2px 20px;
                color: white;
                font-size: 12px;
                text-align: center;
            }

         
        }
    }
}