.custom-tabs{
  width: 100%;

  .custom-tablist{
    display:flex;
    justify-content: center;
    align-items: center;

    .custom-mytab{
      width: 100%;
      list-style-type: none;
      border-radius: 0px;
      text-align:center;
      color: white;
      border-bottom: 1px solid rgb(154, 154, 154);
      padding: 10px;
      margin: 02px;
      border-radius: 05px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
    }

    .react-tabs__tab--selected{
      color:black;
      background-color: #A4AA88;

    }
  }
}

.result-summary{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .pie-chart{
    border: 1px solid white;
    border-radius: 12px;
    padding: 5%;
    width: 300px;
    height: 300px; 
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }

  .result-card{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    display: flex;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: beige;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    padding: 10px;

    h2{
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
    }

    .card-lower{
      display: flex;
      width: 98%;
      justify-content: space-between;
      align-content: flex-start;

      p{
        padding: 0;
        margin: 0;

        span{
          font-weight: 400;
          color: black;
        }
      }

      .card-left{

        p{
          .result-span{
            color: rgb(41, 21, 19);
          }

          .result-number{
            font-size: 18px;
            color: rgb(255, 142, 13);
          }
        }

      }

      .card-right{
        p{
          display: flex;
          flex-direction: column;
          .rank-number{
            font-size: 65px;
            color: rgb(227, 79, 11);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
          }
          .result-span{
            font-size: 14px;

          }
        }
      }
    }

  }


}


.result-details{
  color: white;
  padding: 10px;

  .result-item{

    h3{
      color: white;

    }

    p{
      color: white;
    }

    .res-options{
      list-style-type: none;
      margin-left: 20px;
    }

    .explanation-text{
      color: #FF6A00;
    }

    .question-number{
      margin-right: 10px;
      font-size: 20px;
      color: #ffb300;
    }

  }
}

.go-home{
  display: flex;
  margin: 10px;
  padding: 10px;
  color: white;
  justify-content: flex-start;
  align-items: center;

  .go-home-text{
    margin-left: 10px;
  }
}


