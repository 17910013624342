.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    width: 100%;
    max-height: 80vh; /* Set maximum height to 80% of viewport height */
    overflow-y: auto; /* Allow vertical scrolling if content exceeds the height */
  }
  
  
  .popup-content h2 {
    margin-top: 0;
  }
  
  .popup-content ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .popup-content button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .start-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  