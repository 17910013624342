.form-group input{
    width: 100%;
    overflow: hidden;
}

.form-group{
    width: 90%;
    margin: auto;
    margin-top: 10px;
}

.form-group label{
    color: #eeeeee;
    margin: -15px 0 0 0;
}

.form-control{
    border-radius: 5px !important;
    border: none;
    margin-top: 02px;
    border: 1px solid white;
    background-color: transparent;
    color: #fff;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.signup-qoute{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
}

.signup-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-btn-content{
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.form_style2 .form-control, .form_style2 .form-control:focus {
    color: #fff;
}

input::placeholder {
    color: #ededed; /* Change this to your desired color */
}



/* Hamburger menu icon styling */
.hamburger-menu {
    // position: absolute;
   display: flex;
   justify-content: flex-start;
   width: 98%;
   padding: 10px 0 10px 10px;
   color: white;
   margin-left: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    z-index: 1000; /* Ensure it is on top of other elements */
  }
  
  .hamburger-menu span {
    margin-left: 10px;
  }
  /* react-burger-menu styles */
  .bm-burger-button {
    display: none; /* Hide the default burger button */
  }
  
  .bm-menu {
    background: #2c411b;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  .bm-menu-item {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    color: #d1d1d1;
    text-decoration: none;
  }
  
  .bm-menu-item:hover {
    color: white;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  .menu-user-info{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .menu-avatar{
    width: 60px;
    height: 60px;
  }
  
  .menu-user-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
    ;
  }
  
  .menu-item-text{
    font-size: 13px;
    margin-left: 05px;
  }
  
  .menu-user-details {
    h3{
      padding: 0;
      margin: 0;
      color: white;
      font-size: 16px;
      margin-top: 20px;
    }
    
    p{
      padding: 0;
      margin: 0;
      font-size: 14px;
      margin-bottom: 30px;
      color: rgb(203, 203, 203);
    }
  }
  
  .menu-item{
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
  }
  
  .bm-menu-wrap{
    position: fixed;
    right: inherit; 
    z-index: 1100;
     width: 300px;
      height: 100%;
       transform: translate3d(-100%, 0px, 0px);
        transition: all 0.5s ease 0s;
  }

  .btn{
    background-color: #ff6a00;
    border: none;
  }

  .readonly{
    background-color: #d9d9d92f !important;
  }